<template>
  <div>
    <div class="banner">
      <img :src="banner.thumb" />
      <div class="banner_con">
        <span class="integral_text">会员积分</span>
        <span class="integral_number">{{ integral }}</span>
        <div class="banner_btn">
          <div :class="route==1?'btn3Active':'btn3'" @click="toIntegral('integralShop')">积分商城</div>
          <div :class="route==2?'btn1Active':'btn1'" @click="toIntegral('integralIntro')">获取积分</div>
          <div :class="route==3?'btn2Active':'btn2'" @click="toIntegral('integralRecord')">积分记录</div> 
        </div>
      </div>
    </div>
    <router-view ref="nextRef"></router-view>
  </div>
</template>

<script>
export default {
  name: "integral",
  data() {
    return {
      banner: {},
      integral: 0,
      headers: {
        token: localStorage.getItem("access_token"),
        type:1
      },
      route: 1,
    };
  },
  mounted() {
    this.$parent.routerIndex = 0;
    this.bannerOneban();
    if (this.headers.token) {
      this.getUser();
    }
    console.log(this.$route.name);
  },
  methods: {
    // 获取内部banner、
    bannerOneban() {
      var that = this;
      this.$api.POST(this.$face.bannerOneban, { location: 14 }, function (res) {
        that.banner = res.data;
      });
    },
    //获取用户信息
    getUser() {
      var that = this;
      this.$api.POST(this.$face.memberInfo, {}, function (res) {
        that.$store.commit("userInfo", res.data);
        that.integral = res.data.integral;
      });
    },
    toIntegral(url) {
      //   if (!this.headers.token) {
      //     this.$parent.loginShow();
      //     return;
      //   }
      this.$router.push({
        name: url,
      });
    },
  },
  watch: {
    "$store.state.userInfo"(newval) {
      this.headers.token = localStorage.getItem("access_token");
      this.integral = newval.integral;
    },
    "$route.name"() {
      let route = 1;
      switch (this.$route.name) {
        case "integralShop":
          route = 1;
          break;
        case "integralIntro":
          route = 2;
          break;
        case "integralRecord":
          route = 3;
          break;
      }
      this.route = route;
    },
  },
  activated() {
    this.$nextTick(() => {
      this.$parent.routerIndex = 0;
    });
  },
};
</script>

<style>
.banner {
  width: 100%;
  position: relative;
  background: rgb(246, 246, 246);
  margin-bottom: 15px;
  overflow: hidden;
  min-height: 290px;
}
.banner img {
  width: 100%;
}
.banner_con {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 7%;
  width: 1008px;
  height: 83px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}
.banner_con .integral_text {
  float: left;
  margin-left: 37px;
  margin-top: 45px;
  font-size: 14px;
  color: #333;
}
.banner_con .integral_number {
  margin-top: 26px;
  font-size: 36px;
  float: left;
  color: #5857ff;
  font-weight: bold;
  margin-left:5px;
}
.banner_con .banner_btn {
  float: right;
  margin-right: 49px;
  margin-top: 21px;
}
.banner_con .banner_btn div {
  padding-top: 30px;
  background-repeat: no-repeat;
  float: left;
  background-position: center top;
  margin-left: 46px;
  cursor: pointer;
}
.banner_con .banner_btn .btn1 {
  background-image: url("../../assets/images/integral/get.png");
}
.banner_con .banner_btn .btn1Active {
  background-image: url("../../assets/images/integral/get2.png");
  color: #5957ff;
}
.banner_con .banner_btn .btn2 {
  background-image: url("../../assets/images/integral/record.png");
}
.banner_con .banner_btn .btn2Active {
  background-image: url("../../assets/images/integral/record2.png");
  color: #5957ff;
}
.banner_con .banner_btn .btn3 {
  background-image: url("../../assets/images/integral/shop.png");
}
.banner_con .banner_btn .btn3Active {
  background-image: url("../../assets/images/integral/shop2.png");
  color: #5957ff;
}
</style>